export const WorkData = [
	{
		title: "V-Market",
		desc: "It is a simple store. It is linked to Stripe for making possible to check cridit cart and some payments.",
		tech: ["Python", "Django", "Rest Framework", "Vue.js", "JavaScript", "Djoser"],
		gitlink: "//github.com/SarvarVakhobov/e-commerce",
		site: "//github.com/SarvarVakhobov/e-commerce",
	},
	{
		title: "Company",
		desc: "An online platform is a web application that many companies run at the same time, without interfering with each other.",
		tech: ["Django", "HTML", "CSS", "Jinja"],
		gitlink: "//github.com/SarvarVakhobov/company",
		site: "//github.com/SarvarVakhobov/company",
	},
	{
		title: "User registration",
		desc: "It is fully API based project. It is used JWT for user registration and authentication.",
		tech: ["Django", "Rest Framework", "APIs", "JWT"],
		gitlink: "//github.com/SarvarVakhobov/user_registration",
		site: "//github.com/SarvarVakhobov/user_registration",
	},
	{
		title: "Islamic Dictionary",
		desc: "It is a static website. You can find some words related to Islamic religion.",
		tech: ["Django", "HtML", "CSS", "Jinja"],
		gitlink: "//github.com/SarvarVakhobov/Islamic-Dictionary",
		site: "//github.com/SarvarVakhobov/Islamic-Dictionary",
	},
	{
		title: "Edu CRM",
		desc: "It is a LMS. It is used for making attendance, grading, and other things.",
		tech: ["Django", "Rest Framework", "APIs"],
		gitlink: "//github.com/SarvarVakhobov/educrm",
		site: "//github.com/SarvarVakhobov/educrm",
	},
	{
		title: "Timetable",
		desc: "It is my university project. It is used for making timetable for students and teachers.",
		tech: ["Django", "Jinja", "HTML", "CSS"],
		gitlink: "//github.com/SarvarVakhobov/timetable",
		site: "//github.com/SarvarVakhobov/timetable",
	},
];
