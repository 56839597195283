import {
	FaTrello,
	FaPython,
	FaGithub,
	FaHtml5,
	FaCss3,
	FaAws,
	FaUbuntu,
	FaGitAlt,
} from "react-icons/fa";
import {
	FaLocust,
} from "react-icons/fa6";
import {  } from "react-icons/di";
import {  } from "react-icons/vsc";
import {
	SiCypress,
	SiJira,
	SiTestinglibrary,
	SiPostman,
	SiDjango,
	SiPostgresql,
	} from "react-icons/si";

export const SkillsData = [
	{
		name: "Trello",
		icon: <FaTrello />,
	},
	{
		name: "Jira",
		icon: <SiJira />,
	},
	{
		name: "Cypress",
		icon: <SiCypress />,
	},
	{
		name: "Locust",
		icon: <FaLocust />,
	},
	{
		name: "Testing Library",
		icon: <SiTestinglibrary />,
	},
	{
		name: "Postman",
		icon: <SiPostman />,
	},
	{
		name: "Python",
		icon: <FaPython />,
	},
	{
		name: "Django",
		icon: <SiDjango />,
	},
	{
		name: "Ubuntu",
		icon: <FaUbuntu />,
	},
	{
		name: "PostgreSQL",
		icon: <SiPostgresql />,
	},
	{
		name: "HTML5",
		icon: <FaHtml5 />,
	},
	{
		name: "CSS3",
		icon: <FaCss3 />,
	},
	{
		name: "AWS",
		icon: <FaAws />,
	},
	{
		name: "Git",
		icon: <FaGitAlt />,
	},
	{
		name: "Github",
		icon: <FaGithub />,
	},

];
