import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTelegram} from "react-icons/fa"
import {BiEnvelope} from "react-icons/bi"
import {BsGithub, } from "react-icons/bs"

const NavLinks = ({handleNav}) => {
  return (
    <ul className='nav-links'>
        {/* <li onClick={handleNav} ><Link  to="//twitter.com/SarvarVaxobov" target='_blank' className='nav-link'><FaTwitter /></Link></li> */}
        <li onClick={handleNav} ><Link  to="//www.linkedin.com/in/sarvar-vaxobov" target='_blank' className='nav-link'><FaLinkedin /></Link></li>
        <li onClick={handleNav} ><Link  to="//github.com/SarvarVakhobov" target='_blank' className='nav-link'><BsGithub /></Link></li>
        {/* <li onClick={handleNav} ><Link  to="//www.instagram.com/Sarvar_Vaxobov" target='_blank' className='nav-link'><BsInstagram /></Link></li> */}
        <li onClick={handleNav} ><Link  to="//mailto:Sarvarbek0125@gmail.com" target='_blank' className='nav-link'><BiEnvelope /></Link></li>
        <li onClick={handleNav} ><Link  to="//t.me/+998900011076" target='_blank' className='nav-link'><FaTelegram/></Link></li>
  </ul>
  )
};

export default NavLinks;
