import React from 'react';
import "../styles/About.css"
import { motion } from "framer-motion";
import ProfileImg from '../images/profile.jpg'

const About = () => {  
  
  const horizontal ={
    x:0, 
    opacity: 1, 
    transition:{type: 'spring', duration: 2,bounce: 0.3}
  }

  return (
      <>
          <div  className="about" id='about'>
              <div className="container">
                  <motion.div initial={{x: '-100%', opacity: 0}} whileInView={horizontal} viewport={{ once: true }} className="heading">
                    <p className="heading-sub-text">Who I am</p>
                    <p className='heading-text'>About Me</p>
                  </motion.div>
                  <div className="split-about">
                    <motion.div initial={{x: '-100%', opacity: 0}} whileInView={horizontal} className="about-content">
                        <p>Hello! My name is Sarvarbek, I am a passionate QA Engineer with a strong focus on delivering high-quality software. I have extensive experience in manual, exploratory, end-to-end (E2E), and automation testing methodologies. I am proficient in using Cypress as my primary test automation tool and leverage project management platforms like Jira and Trello to stay organized and efficient.</p>
                        <br />
                        <p>Additionally, I enjoy creating things that live on the internet. My interest in web development started back in 2021 when I decided to try out for my first school project — turns out putting together a simple website taught me a lot about Python and a bit HTML/CSS </p>
                    </motion.div>
                    <motion.div initial={{x: '50', opacity: 0}} whileInView={horizontal}  className='about-img'>
                        <img src={ProfileImg} alt="Profile" />
                    </motion.div>
                  </div>
              </div>
          </div>
      </>
  )
};

export default About;
